<template>
	<div>
		<Header :navbg='true'></Header>
		<div class="content">

			<van-swipe :autoplay="3000" indicator-color="white" class="banner">
				<van-swipe-item v-for="(img,index) in bannerList" :key="index">
					<!-- <a :href="img.link" class="banner-warp"> -->
						<!-- <h3 class="title">{{img.title}}</h3> -->
						<!-- <p class="desc">{{img.desc}}</p> -->
						<!-- <img :src="img.image" class="banner-img"> -->
					<!-- </a> -->
					<van-image :src="img.image" fit="cover" class="banner-img" @click="linkClick(img.link)" />
				</van-swipe-item>
			</van-swipe>

			<div class="house-type page">
				<div class="title">小院生活</div>
				<div class="desc">小院生活紧跟时代发展步伐，结合城市发展规划及人们对人居舒适度的期待和要求，力求为城市人居发展提供智慧化的住宅解决方案，实现高效率的城市生活和高舒适度、低密度的生活模式相统一。团队经过多年潜心研发，匠心精筑，最终形成了区间面积100-200㎡，前庭后院双花园、容积率趋于1.0的小院式经典户型，可满足多类人群刚需及改善性住房需求，享受静谧惬意的庭院生活。</div>
				<div class="house-list">
					<div class="item" @click="houseType(105)">
						<img src="../../../assets/images/project/dooryard/100.jpg" alt="">
						<div class="area">105㎡</div>
					</div>
					<div class="item" @click="houseType(138)">
						<img src="../../../assets/images/project/dooryard/140.jpg" alt="">
						<div class="area">138㎡</div>
					</div>
					<div class="item" @click="houseType(163)">
						<img src="../../../assets/images/project/dooryard/165.jpg" alt="">
						<div class="area">163㎡</div>
					</div>
					<div class="item" @click="houseType(210)">
						<img src="../../../assets/images/project/dooryard/200.jpg" alt="">
						<div class="area">210㎡</div>
					</div>
				</div>
			</div>

			<div class="project-warp">
				<div class="page">
					<div class="item">
						<img src="../../../assets/images/project/dooryard/wlc2.jpg" alt="">
						<div class="item-warp">
							<div class="title">未来城</div>
							<div class="desc">经开·未来城位于渭南市经济技术开发区田园都市区核心位置，紧邻渭南中学。总占地174亩,总建筑面积12.7万㎡，共计83栋低层高品质庭院住宅。建筑形体设计采用新古典建筑风格,容积率1.2,以140㎡改善型三居室为主,社区配备多层级景观绿地、娱乐健身设施、小院生活馆及社区商业街,满足静谧高品质生活需求。</div>
							<div class="more-warp">
								<div class="more" @click="projectClick('futureCity')">了解更多</div>
							</div>
						</div>
					</div>

					<div class="project-list">
						<div class="project">
							<img src="../../../assets/images/project/dooryard/sbw.jpg" alt="">
							<div class="desc">水半湾</div>
						</div>
						<div class="project">
							<img src="../../../assets/images/project/dooryard/ayhf.jpg" alt="">
							<div class="desc">奥韵华府</div>
						</div>
						<div class="project">
							<img src="../../../assets/images/project/dooryard/syf.jpg" alt="">
							<div class="desc">水一方</div>
						</div>
						<div class="project">
							<img src="../../../assets/images/project/dooryard/wlc.jpg" alt="">
							<div class="desc">未来城</div>
						</div>
					</div>
				</div>

			</div>

			<div class="icon-warp page">

				<div class="item">
					<i class="iconfont icon-safe"></i>
					<div class="title">抗震性最好</div>
					<div class="desc">短肢剪力墙结构</div>
					<div class="desc">现浇结构至少30％</div>
				</div>
				<div class="item">
					<i class="iconfont icon-punch"></i>
					<div class="title">降板同层排水</div>
					<div class="desc">
						洁具位置不受限
					</div>
					<div class="desc">低噪声+舒适高度</div>
				</div>
				<div class="item">
					<i class="iconfont icon-brand"></i>
					<div class="title">热水循环</div>
					<div class="desc">
						多点位同时供应

					</div>
					<div class="desc">舒适，节水，节能</div>
				</div>
				<div class="item">
					<i class="iconfont icon-we"></i>
					<div class="title">断桥铝外开窗</div>
					<div class="desc">
						气密性更好

					</div>
					<div class="desc">不占室内空间</div>
				</div>

				<div class="item">
					<i class="iconfont icon-square"></i>
					<div class="title">VELUX天窗</div>
					<div class="desc">
						引光入室+增加自然通风

					</div>
					<div class="desc">防水更好，可定制升级</div>
				</div>
				<div class="item">
					<i class="iconfont icon-evaluate"></i>
					<div class="title">新风预留</div>
					<div class="desc">
						净化室内空气

					</div>
					<div class="desc">除菌、消毒、祛湿</div>
				</div>
				<div class="item">
					<i class="iconfont icon-flashlightopen"></i>
					<div class="title">空气源热泵</div>
					<div class="desc">
						温度波动小，无风口

					</div>
					<div class="desc">冬夏一套设备</div>
				</div>
				<div class="item">
					<i class="iconfont icon-shop"></i>
					<div class="title">光伏屋面</div>
					<div class="desc">
						绿色低碳

					</div>
					<div class="desc">节能减耗</div>
				</div>

			</div>

		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from '@/components/head2022.vue'
	import Footer from '@/components/foot2022.vue'
	import utils from '@/common/utils'
	import {
		mapState
	} from 'vuex'
	export default {
		name: "productDooryard",
		data() {
			return {
				bannerList: [],
			}
		},
		components: {
			Header,
			Footer,
		},
		computed: {
			...mapState(['ocApi']),
		},
		created() {
			this.getBanner();
		},
		methods: {
			linkClick: utils.debounce(function(url) {
				if (url && url != '') {
					window.location.href = url;
				}
			}, 200, true),
			getBanner: function() {
				let that = this;
				that.$axios.get(that.ocApi + 'book/banner', {
						params: {
							'name': 'mobile_product_dooryard',
						}
					})
					.then(res => {
						if (res.data.status == 1) {
							that.bannerList = res.data.data.images
						} else {
							that.$message.error(res.data.message);
						}
					})
			},
			houseType: utils.debounce(function(e) {
				this.$router.push({
					name: 'productDooryardDetail',
					params: {
						houseType: e
					}
				});
			}, 500, true),

			projectClick: utils.debounce(function(name) {
				this.$router.push({
					name
				});
			}, 500, true),

		}
	}
</script>

<style lang="scss" scoped>
	.content {
		padding-bottom: 20px;
	}

	/*banner*/
	.banner :deep(.van-swipe__indicators) {
		bottom: 30px;
	}

	.banner :deep(.van-swipe__indicators) .van-swipe__indicator {
		border-radius: 0;
		width: 16px;
		height: 2px;
		background: #000;
	}
	.banner .banner-img {
		width: 100%;
	}
	.banner-warp {
		line-height: 0;
		position: relative;
		display: block;
	}

	.banner-warp .title {
		position: absolute;
		top: 200px;
		width: 100%;
		text-align: center;
		color: #fff;
		font-size: 24px;
	}

	.banner-warp .desc {
		position: absolute;
		top: 250px;
		width: 100%;
		text-align: center;
		color: #fff;
		font-size: 24px;
	}

	.banner-warp .banner-img {
		width: 100%;
	}

	/*banner*/

	.page {
		padding: 0 16px;
	}

	.house-type {
		padding: 30px 16px;
	}

	.house-type .title {
		font-size: 19px;
		line-height: 19px;
		text-align: center;
	}

	.house-type .desc {
		color: #999999;
		width: 100%;
		margin: 12px auto 0;
		text-align: center;
	}

	.house-type .house-list {
		margin-top: 20px;
		display: flex;
		flex-wrap: wrap;
	}

	.house-type .house-list .item {
		width: 50%;
		position: relative;
	}

	.house-type .house-list .item>img {
		width: 100%;
	}

	.house-type .house-list .item>.area {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.5);
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 20px;
		font-weight: 600;
	}

	.project-warp {
		padding: 30px 0;
		background-color: #f6f6f6;
	}

	.project-warp .item {
		background-color: #fff;
		width: 100%;
		text-align: center;
	}

	.project-warp .item>img {
		width: 100%;
	}

	.project-warp .item>.item-warp {
		padding: 20px 17px 30px;
	}

	.project-warp .item>.item-warp .title {
		font-size: 19px;
		line-height: 19px;
	}

	.project-warp .item>.item-warp .desc {
		font-size: 14px;
		line-height: 19px;
		margin-top: 20px;
		color: #999999;
	}

	.project-warp .item>.item-warp .more {
		padding: 10px 28px;
		font-size: 14px;
		color: #999999;
		border: 1px solid #999999;
		border-radius: 100px;
		margin-top: 34px;
		display: inline-block;
		line-height: 14px;
	}

	.project-warp .project-list {
		margin-top: 20px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.project-warp .project-list .project {
		width: 49%;
		margin-bottom: 11px;
		position: relative;
	}

	.project-warp .project-list .project>img {
		width: 100%;
	}

	.project-warp .project-list .project>.desc {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 15px;
		color: #fff;
		background-color: rgba(0, 0, 0, 0.5);
		left: 0;
		top: 0;
		position: absolute;
	}

	.icon-warp {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding-top: 15px;
	}

	.icon-warp .item {
		width: 50%;
		text-align: center;
		padding: 15px 0;
	}

	.icon-warp .item .iconfont {
		font-size: 39px;
		line-height: 39px;
		margin-top: 30px;
	}

	.icon-warp .item .title {
		font-size: 18px;
		line-height: 18px;
		margin-top: 22px;
		margin-bottom: 10px;
	}

	.icon-warp .item .desc {
		font-size: 14px;
		color: #999999;
		line-height: 20px;
	}
</style>
